@font-face {
  font-family: 'Alpha Font';
  font-style: normal;
  font-weight: 1 999;
  src: local('Alpha Font'), local('Alpha-Book'),
    url('./fonts/Alpha-Book.otf') format('truetype');
}

@font-face {
  font-family: 'Alpha Font';
  font-style: italic;
  font-weight: 1 999;
  src: local('Alpha Italic Font'), local('Alpha-BookItalic'),
    url('./fonts/Alpha-BookItalic.otf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Alpha Font', monospace;
  /* width: calc( 100vw - 18px); */
  min-width: 310px;

  /* background-color: #282c34; */
  /* padding-right: 17px;
  overflow: hidden;
  overflow-y: overlay;
  width: 100vw; */
}

html {
  overflow-y:scroll;
}

html {
  text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
}

:root {
  touch-action: pan-x pan-y;
  height: 100%
}

.App {
  padding-right: 8px;
  padding-left: 8px;
  overflow: hidden;
}

p {
  line-height: 1.5;
}

a {
  color: black;
}


.transition-content-of-chapter{
  background-color: white;
}






.fade-appear,
.fade-enter {
opacity: 0;
z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
opacity: 1;
transition: opacity 450ms linear 250ms;
}
.fade-exit {
opacity: 1;
}
.fade-exit.fade-exit-active {
opacity: 0;
transition: opacity 150ms linear;
}